.form{
    margin:auto;
    width: 30%;
}

.container{
    margin-top: 10px;
    margin-left: 50px;
}

.home-leftbox {
    float:left;
    background:white;
    padding-top: 50px;
    padding-left: 150px;
    height:715px;
    width:30%;
}

.home-rightbox {
    float:left;
    background:white;
    height:715px;
    width:70%;
    padding-top: 50px;
    padding-left: 100px;
}


.leftbox {
    float:left;
    background:white;
    width:20%;
    height:500px;
}

.middlebox {
    float:left;
    background:white;
    width:35%;
    height:500px;
    left-margin: 200px;
    padding-left: 150px;
}

.rightbox {
    float:left;
    background:white;
    width:40%;
    height:500px;
    left-margin: 200px;
}

.divider{
    width:5px;
    height:auto;
    display:inline-block;
}

h3 {
  margin: 0px;
  padding: 10px 10px 10px 10px;
  text-align: right;
  display: inline-block;
}
